import Fab from '@material-ui/core/Fab';
import React from 'react';
import PropTypes from 'prop-types';
import classes from './style.module.scss';

const Start = ({ clickStart }) => (
  <div className={classes.start}>
    <div className={classes.header}>
      <p className={classes.big}>Olá!</p>
      <p>Pronto para começar a sua vistoria de desocupação?</p>
    </div>
    <div className={classes.bottomSection}>
      <Fab
        variant="extended"
        aria-label="Delete"
        className={classes.startButton}
        classes={{
          label: classes.label,
        }}
        onClick={clickStart}
      >
        Começar
      </Fab>
    </div>
  </div>
);

Start.propTypes = {
  clickStart: PropTypes.func.isRequired,
};

export default Start;
