import React from 'react';
import PropTypes from 'prop-types';
import './BottomOptions.css';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    height: '122px',
    backgroundColor: '#586120',
    paddingTop: '20px',
  },
  button: {
    margin: theme.spacing(1),
    background: '#879238',
    color: 'white',
    textTransform: 'none',
    width: '150px',
    height: '42px',
    borderRadius: '50px',
    marginLeft: '0px',
    marginRight: '0px'
  },
  label: {
    fontWeight: 'bold',
    fontSize: '18px',
    paddingLeft: '40px',
    paddingRight: '40px',
    textTransform: 'none'
  }
}));

export default function BottomAppBar(props) {
  const classes = useStyles();
  let option;

  if (props.step === 'ItemsReview') {
    option =
      <>
        <Grid item xs={6} className="Center-box">
          <Fab
            variant="extended"
            aria-label="Não"
            className={classes.button}
            classes={{
              label: classes.label,
            }}
            onClick={props.firstFunction}
            style={{
              background: props.buttonsColor,
            }}
          >
            Não
          </Fab>
        </Grid>
        <Grid item xs={6} className="Center-box">
          <Fab
            variant="extended"
            aria-label="Sim"
            className={classes.button}
            classes={{
              label: classes.label,
            }}
            onClick={props.secondFunction}
            style={{
              background: props.buttonsColor,
            }}
          >
            Sim
          </Fab>
        </Grid>
      </>;
  } else {
    if (props.step === 'AskServices') {
      option =
      <Grid item xs={12} className="Center-box">
        <Fab
          variant="extended"
          aria-label="Avançar"
          className={classes.button}
          classes={{
            label: classes.label,
          }}
          onClick={props.firstFunction}
          style={{ background: props.buttonsColor }}
        >
          Finalizar
        </Fab>
      </Grid>;
    } else {
        option =
        <Grid item xs={12} className="Center-box">
          <Fab
            variant="extended"
            aria-label="Avançar"
            className={classes.button}
            classes={{
              label: classes.label,
            }}
            onClick={props.firstFunction}
            disabled={ props.disabled }
            style={{ background: props.buttonsColor }}
          >
            Avançar
          </Fab>
        </Grid>;
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} style={{ background: props.color }}>
        <Grid container spacing={1}>
          <Grid item xs={12} className="Center-box">
            <span className="Title-text">
              { props.text }
            </span>
          </Grid>
          { option }
        </Grid>
      </AppBar>
    </React.Fragment>
  );
}

BottomAppBar.propTypes = {
  step: PropTypes.string.isRequired,
  firstFunction: PropTypes.func,
  secondFunction: PropTypes.func,
  color: PropTypes.string.isRequired,
  buttonsColor: PropTypes.string.isRequired,
}
