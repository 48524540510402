import { connect } from 'react-redux';
import { nextItem, openGeneralReview } from '../../actions';
import RoomSteps from './RoomStepsComponent';

const mapStateToProps = state => ({
  building: state.building,
  currentRoom: state.currentRoom,
});

const mapDispatchToProps = dispatch => ({
  clickNext: () => dispatch(nextItem()),
  openGeneralReview: () => dispatch(openGeneralReview()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoomSteps);
