import React from 'react';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import classes from './style.module.scss';

const RoomSteps = ({ building, clickNext, color, currentRoom, openGeneralReview }) => {
  let image;

  if (building.building.rooms[currentRoom].photos[0]) {
    image = building.building.rooms[currentRoom].photos[0].filePath;
  }

  return (
    <div className={classes.roomSteps} style={{ background: `url(${image}) ${color}` }}>
      <div className={classes.header}>
        <p className={classes.big}>
          Dirija-se à dependência
          <br />
          <span className={classes.roomName}>{building.building.rooms[currentRoom].name}</span>
        </p>
        <p>
          Observe o estado de conservação atual. Fotografe e relate detalhes em desacordo dando especial atenção às
          paredes, pisos, teto e testando instalações elétricas e hidráulicas quando for o caso.
        </p>
      </div>
      <div className={classes.bottomSection}>
        <p className={classes.pagination}>
          {currentRoom}/{Object.keys(building.building.rooms).length}
        </p>
        <Fab
          variant="extended"
          aria-label="Delete"
          className={classes.startButton}
          classes={{
            label: classes.label,
          }}
          onClick={Object.keys(building.building.rooms[currentRoom].items).length > 0 ? clickNext : openGeneralReview}
        >
          Avançar
        </Fab>
      </div>
    </div>
  );
};

export default RoomSteps;

RoomSteps.propTypes = {
  color: PropTypes.string.isRequired, // prop
  building: PropTypes.object.isRequired, // redux
  currentRoom: PropTypes.number.isRequired, // redux
  clickNext: PropTypes.func.isRequired, // redux
  openGeneralReview: PropTypes.func.isRequired, // redux
};
