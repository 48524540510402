import { connect } from 'react-redux';
import { setInitialState } from '../../actions';
import App from './AppComponent';

const mapDispatchToProps = dispatch => ({
  setInitialState: state => dispatch(setInitialState(state)),
});

export default connect(
  null,
  mapDispatchToProps
)(App);
