import formatDateTimeToString from '../services/date';

const index = (state = [], action) => {
  var item = state.currentRoom;
  var currentColor = state.currentColor;

  switch (action.type) {
    case 'SET_INITIAL_STATE':
      return action.obj;
    case 'NEXT_ROOM':
      if (currentColor === state.colors.length - 1 || state.currentRoom === 0) {
        currentColor = 0;
      } else {
        currentColor++;
      }
      return {
        ...state,
        currentItem: 0,
        currentRoom: state.currentRoom + 1,
        generalReview: false,
        roomDisagreementOverview: false,
        currentColor: currentColor,
      };
    case 'PREVIOUS_ROOM':
      return {
        ...state,
        currentRoom: state.currentRoom - 1,
      };
    case 'NEXT_ITEM':
      return {
        ...state,
        currentItem: state.currentItem + 1,
        reportDisagreements: false,
        roomDisagreementOverview: false,
      };
    case 'PREVIOUS_ITEM':
      return {
        ...state,
        currentItem: state.currentItem - 1,
      };
    case 'REPORT_DISAGREEMENTS':
      return {
        ...state,
        reportDisagreements: true,
      };
    case 'CANCEL_DISAGREEMENTS':
      return {
        ...state,
        reportDisagreements: false,
      };
    case 'OPEN_GRAL_REVIEW':
      return {
        ...state,
        reportDisagreements: false,
        generalReview: true,
      };
    case 'CLOSE_GRAL_REVIEW':
      return {
        ...state,
        generalReview: false,
      };
    case 'OPEN_DISAGREEMENTS_REVIEW':
      return {
        ...state,
        roomDisagreementOverview: true,
        reportDisagreements: false,
      };
    case 'CLOSE_DISAGREEMENTS_REVIEW':
      return {
        ...state,
        roomDisagreementOverview: false,
        generalReview: true,
      };
    case 'OPEN_SERVICES':
      return {
        ...state,
        roomDisagreementOverview: false,
        generalReview: false,
        askServices: true,
      };
    case 'CLOSE_SERVICES':
      return {
        ...state,
        roomDisagreementOverview: true,
        askServices: false,
      };
    case 'SET_REPORT_OBSERVATIONS':
      if (!state.generalReview) {
        item = item + '.' + state.currentItem;
      }
      return {
        ...state,
        pwa_data: {
          ...state.pwa_data,
          damages: {
            ...state.pwa_data.damages,
            [item]: {
              ...state.pwa_data.damages[item],
              creationDate: formatDateTimeToString(new Date()),
              description: action.text,
            },
          },
        },
      };
    case 'SET_REPORT_STATUS':
      if (!state.generalReview) {
        item = item + '.' + state.currentItem;
      }
      return {
        ...state,
        pwa_data: {
          ...state.pwa_data,
          damages: {
            ...state.pwa_data.damages,
            [item]: {
              ...state.pwa_data.damages[item],
              creationDate: formatDateTimeToString(new Date()),
              evaluation: action.text,
            },
          },
        },
      };
    case 'SET_REPORT_PHOTO':
      if (!state.generalReview) {
        item = item + '.' + state.currentItem;
      }

      return {
        ...state,
        pwa_data: {
          ...state.pwa_data,
          damages: {
            ...state.pwa_data.damages,
            [item]: {
              ...state.pwa_data.damages[item],
              creationDate: formatDateTimeToString(new Date()),
              damagePhotos: state.pwa_data.damages[item].damagePhotos.concat(action.text),
            },
          },
        },
      };
    case 'SET_SERVICE':
      let services = state.pwa_data.services;
      if (services.includes(action.text)) {
        var index = services.indexOf(action.text);
        if (index > -1) {
          services.splice(index, 1);
        }
      } else {
        services.push(action.text);
      }
      return {
        ...state,
        pwa_data: {
          ...state.pwa_data,
          services: services,
        },
      };
    case 'SET_SERVICES_OBSERVATIONS':
      return {
        ...state,
        pwa_data: {
          ...state.pwa_data,
          servicesObservation: action.text,
        },
      };
    default:
      return state;
  }
};

export default index;
