import RadioGroup from '@material-ui/core/RadioGroup';
import CameraAlt from '@material-ui/icons/CameraAlt';
import Grid from '@material-ui/core/Grid';
import ChatBubbleOutline from '@material-ui/icons/ChatBubbleOutline';
import OutlinedFlag from '@material-ui/icons/OutlinedFlag';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import PropTypes from 'prop-types';
import React from 'react';
// import EXIF from 'exif-js';
// import loadImage from 'blueimp-load-image';
import { Carousel } from 'react-responsive-carousel';
import config from '../../config';
import apiService from '../../services/api-service';
import resizePhoto from '../../services/resize-photo';
import formatDateTimeToString from '../../services/date';
import commonClasses from '../Common/style.module.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import classes from './style.module.scss';
import './carousel.scss';

export default class Report extends React.Component {
  static propTypes = {
    buttonsColor: PropTypes.string.isRequired,
    handleNewPhoto: PropTypes.func.isRequired,
    handleStatusChange: PropTypes.func.isRequired,
    handleTextChange: PropTypes.func.isRequired,
    observationsValue: PropTypes.string,
    photos: PropTypes.array,
    statusValue: PropTypes.object,
    uploadPath: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      photos: [],
    };
  }

  // getMobileOperatingSystem = () => {
  //   const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  //   // Windows Phone must come first because its UA also contains "Android"
  //   if (/windows phone/i.test(userAgent)) {
  //     return 'Windows Phone';
  //   }

  //   if (/android/i.test(userAgent)) {
  //     return 'Android';
  //   }

  //   // iOS detection from: http://stackoverflow.com/a/9039885/177710
  //   if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
  //     return 'iOS';
  //   }

  //   return 'unknown';
  // };

  openCamera = async () => {
    const supported = 'mediaDevices' in navigator;

    if (!supported) {
      document.getElementById('file-input').click();
      return;
    }

    try {
      document.getElementById('file-input').click();
      return; // force using old way for now

      // this.setState({ showCamera: true });
      // const os = this.getMobileOperatingSystem();

      // let constraints;

      // if (os === 'iOS') {
      //   constraints = { video: { width: 1920, facingMode: 'environment' } };
      // } else {
      //   constraints = {
      //     video: {
      //       width: { ideal: 1920 },
      //       height: { ideal: 1920 },
      //       facingMode: 'environment',
      //     },
      //   };
      // }

      // // ask for permission and show camera image
      // const stream = await navigator.mediaDevices.getUserMedia(constraints);

      // this.video.style.display = 'none';
      // this.video.srcObject = stream;

      // setTimeout(() => {
      //   this.video.style.display = 'block';
      //   if (this.video.offsetWidth > this.video.offsetHeight) {
      //     this.video.style.height = '100%';
      //     this.video.style.width = 'auto';
      //   } else {
      //     this.video.style.width = '100%';
      //     this.video.style.height = 'auto';
      //   }
      // }, 500);
    } catch (err) {
      console.log('Could not access the camera. Error: ', err);
      console.log('Trying polyfill');
      // this.setState({ showCamera: false });
      // document.getElementById('file-input').click();
    }
  };

  onBackgroundLoadRequest = async () => {
    const reg = await navigator.serviceWorker.ready;

    await reg.sync.register('sendData');
  };

  // takePhoto = () => {
  //   const wrapperWidth = this.cameraImage.offsetWidth;
  //   const wrapperHeight = this.cameraImage.offsetHeight;
  //   const videoWidth = this.video.offsetWidth;
  //   const videoHeight = this.video.offsetHeight;
  //   const canvas = document.createElement('canvas');

  //   canvas.width = wrapperWidth * 2;
  //   canvas.height = wrapperHeight * 2;

  //   const context = canvas.getContext('2d');
  //   context.drawImage(
  //     this.video,
  //     -1 * (videoWidth - wrapperWidth),
  //     -1 * (videoHeight - wrapperHeight),
  //     videoWidth * 2,
  //     videoHeight * 2
  //   );

  //   canvas.toBlob(
  //     blob => {
  //       let orientation = true;

  //       EXIF.getData(blob, () => {
  //         const allMetaData = EXIF.getAllTags(this);

  //         if (allMetaData.Orientation === 6) {
  //           orientation = 6;
  //         }
  //       });

  //       this.handleFile(blob, orientation);
  //       this.closeCamera();
  //     },
  //     'image/jpeg',
  //     1
  //   );
  // };

  // closeCamera = () => {
  //   this.video.srcObject.getVideoTracks().forEach(track => track.stop());
  //   this.setState({ showCamera: false });
  // };

  takePhotoPolyfill = e => {
    const file = e.target.files[0];
    this.handleFile(file);
  };

  handleFile = async file => {
    const { handleNewPhoto, uploadPath } = this.props;

    const { resizedPhoto, url } = await resizePhoto(file);

    console.log('resizedPhoto');
    console.log(resizedPhoto);

    this.setState(state => ({
      photos: [...state.photos, url],
    }));

    let ext;
    switch (resizedPhoto.type) {
      case 'image/bmp':
        ext = 'bmp';
        break;
      case 'image/gif':
        ext = 'gif';
        break;
      case 'image/png':
        ext = 'png';
        break;
      case 'image/tiff':
        ext = 'tif';
        break;
      default:
        ext = 'jpg';
        break;
    }

    const imageName = `${Math.random()
      .toString(36)
      .substring(2) + Date.now().toString(36)}.${ext}`;
    const urlUpload = `${config.S3_BASE_URL}/${uploadPath}/${imageName}`;

    apiService
      .put(urlUpload, resizedPhoto, {
        headers: { 'Content-Type': resizedPhoto.type, 'x-amz-acl': 'public-read-write' },
      })
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
        this.onBackgroundLoadRequest();
      });

    handleNewPhoto({
      id: imageName,
      date: formatDateTimeToString(new Date()),
      localPath: url,
      filePath: urlUpload,
    });
  };

  render() {
    // const { showCamera } = this.state;
    const { buttonsColor, handleStatusChange, handleTextChange, observationsValue, photos, statusValue } = this.props;
    const status = [
      {
        label: 'Bom',
        color: '#98B10F',
      },
      {
        label: 'Novo',
        color: '#559AAA',
      },
      {
        label: 'Ruim',
        color: '#C62C13',
      },
      {
        label: 'Regular',
        color: '#E7AF04',
      },
    ];

    const elements = [];

    elements.push(
      <div className={commonClasses.scrollArea}>
        <div className={classes.imagesGallery}>
          <Carousel showThumbs={false} showStatus={false} showArrows={false}>
            <div className={classes.cameraButton}>
              <input
                style={{ display: 'none' }}
                id="file-input"
                type="file"
                onChange={e => this.takePhotoPolyfill(e)}
                accept="image/*"
                capture="environment"
              />
              <button type="button" className={classes.showChamera} onClick={this.openCamera}>
                <div className={classes.circle} style={{ background: buttonsColor }}>
                  <CameraAlt style={{ fontSize: 62, color: 'white' }} />
                </div>
                <div className={classes.cameraTextBox}>
                  <span style={{ color: '#979797' }}>Registre fotos dos desacordos</span>
                </div>
              </button>
            </div>
            {photos.map((value, index) => (
              <div key={index} className={classes.cameraBox}>
                <div className={classes.image}>
                  <img src={value.localPath} alt="" />
                </div>
              </div>
            ))}
          </Carousel>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={0} className={commonClasses.informationBox}>
              <Grid item xs={1}>
                <ChatBubbleOutline />
              </Grid>
              <Grid item xs={11}>
                <div>
                  <span className={classes.reportCategoryTitle}>OBSERVAÇŌES</span>
                </div>
                <div className={classes.reportObservations}>
                  <FormGroup>
                    <TextField
                      placeholder=""
                      variant="outlined"
                      id="custom-css-outlined-input"
                      multiline
                      value={observationsValue}
                      onChange={e => handleTextChange(e.target.value)}
                    />
                  </FormGroup>
                </div>
              </Grid>
              <Grid item xs={12} className={commonClasses.separatorLine} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={0} className={commonClasses.informationBox}>
              <Grid item xs={1}>
                <OutlinedFlag />
              </Grid>
              <Grid item xs={11}>
                <div>
                  <span className={classes.reportCategoryTitle}>ESTADO</span>
                </div>
                <FormControl component="fieldset" className={classes.checkStateBox}>
                  <RadioGroup
                    aria-label="position"
                    name="position"
                    value={statusValue}
                    onChange={e => handleStatusChange(e.target.value)}
                    row
                    className={classes.checkOptions}
                  >
                    <FormControlLabel
                      value={JSON.stringify(status[1])}
                      control={<Radio color="default" />}
                      label="Novo"
                      labelPlacement="end"
                      className={classes.controlLabel}
                    />
                    <FormControlLabel
                      value={JSON.stringify(status[0])}
                      control={<Radio color="default" />}
                      label="Bom"
                      labelPlacement="end"
                      className={classes.controlLabel}
                    />
                    <FormControlLabel
                      value={JSON.stringify(status[2])}
                      control={<Radio color="default" />}
                      label="Regular"
                      labelPlacement="end"
                      className={classes.controlLabel}
                    />
                    <FormControlLabel
                      value={JSON.stringify(status[3])}
                      control={<Radio color="default" />}
                      label="Ruim"
                      labelPlacement="end"
                      className={classes.controlLabel}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );

    // if (showCamera) {
    //   elements.push(
    //     <div className={classes.camera}>
    //       <div
    //         className={classes.cameraImage}
    //         ref={cameraImage => {
    //           this.cameraImage = cameraImage;
    //         }}
    //       >
    //         <video
    //           autoPlay
    //           muted
    //           playsInline
    //           controls={false}
    //           ref={video => {
    //             this.video = video;
    //           }}
    //         />
    //       </div>
    //       <button type="button" className={classes.shutterButton} onClick={this.takePhoto} />
    //       <button type="button" className={classes.closeCamera} onClick={this.closeCamera}>
    //         Sair
    //       </button>
    //     </div>
    //   );
    // }

    return elements;
  }
}
