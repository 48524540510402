import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../Loading';
import classes from './style.module.scss';

const FinishComponent = ({ busy, pendingImages }) => {
  if (busy) return <Loading>{pendingImages} imagens pendentes</Loading>;

  return (
    <div className={classes.finish}>
      <div className={classes.header}>
        <p className={classes.big}>Pronto!</p>
        <p>A vistoria de desocupação foi realizada com sucesso.</p>
        <p className={classes.small}>Em breve o proprietário do imóvel será notificado por email.</p>
      </div>
    </div>
  );
};

FinishComponent.propTypes = {
  busy: PropTypes.bool.isRequired,
  pendingImages: PropTypes.number.isRequired,
};

export default FinishComponent;
