import { connect } from 'react-redux'
import { nextItem } from '../actions'
import  ItemsReview from '../components/ItemsReview'

const mapStateToProps = (state) => ({
  currentRoom: state.currentRoom,
  currentItem: state.currentItem,
  item: state.building.building.rooms[state.currentRoom].items[state.currentRoom + "." + state.currentItem]
})

const mapDispatchToProps = (dispatch) => ({
    clickNext: () => dispatch(nextItem()),
  })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemsReview)