const { protocol, hostname, port } = window.location;

const apiBaseUrl = () => {
  switch (hostname) {
    case 'p.vistori.as':
      return `${protocol}//painel.redevistorias.com.br/api/public/pwa`;
    case 'p.sb.vistori.as':
      return `${protocol}//painel.sandbox.redevistorias.com.br/api/public/pwa`;
    case 'p.dev.vistori.as':
      return `${protocol}//painel.dev.redevistorias.com.br/api/public/pwa`;
    default:
      return `https://painel.dev.redevistorias.com.br/api/public/pwa`;
  }
};

const config = {
  BASE_URL: `${protocol}//${hostname}:${port}`,
  API_BASE_URL: apiBaseUrl(),
  S3_BASE_URL: 'https://s3.amazonaws.com',
};

export default config;
