export const setInitialState = obj => ({
  type: 'SET_INITIAL_STATE',
  obj,
});

export const nextRoom = text => ({
  type: 'NEXT_ROOM',
});

export const previousRoom = text => ({
  type: 'PREVIOUS_ROOM',
});

export const nextItem = text => ({
  type: 'NEXT_ITEM',
});

export const previousItem = text => ({
  type: 'PREVIOUS_ITEM',
});

export const reportDisagreement = text => ({
  type: 'REPORT_DISAGREEMENTS',
});

export const cancelDisagreements = text => ({
  type: 'CANCEL_DISAGREEMENTS',
});

export const openGeneralReview = text => ({
  type: 'OPEN_GRAL_REVIEW',
});

export const closeGeneralReview = text => ({
  type: 'CLOSE_GRAL_REVIEW',
});

export const openDisagreementsOverview = text => ({
  type: 'OPEN_DISAGREEMENTS_REVIEW',
});

export const closeDisagreementsOverview = text => ({
  type: 'CLOSE_DISAGREEMENTS_REVIEW',
});

export const openServices = text => ({
  type: 'OPEN_SERVICES',
});

export const closeServices = text => ({
  type: 'CLOSE_SERVICES',
});

export const setReportObservations = text => ({
  type: 'SET_REPORT_OBSERVATIONS',
  text,
});

export const setReportStatus = text => ({
  type: 'SET_REPORT_STATUS',
  text,
});

export const setReportPhoto = text => ({
  type: 'SET_REPORT_PHOTO',
  text,
});

export const setService = text => ({
  type: 'SET_SERVICE',
  text,
});

export const setServicesObservations = text => ({
  type: 'SET_SERVICES_OBSERVATIONS',
  text,
});
