import { connect } from 'react-redux';
import {
  nextRoom,
  previousRoom,
  nextItem,
  previousItem,
  reportDisagreement,
  cancelDisagreements,
  openGeneralReview,
  openDisagreementsOverview,
  openServices,
  closeGeneralReview,
  closeDisagreementsOverview,
  closeServices,
  setInitialState,
} from '../../actions';
import Base from './BaseComponent';

const mapStateToProps = state => ({
  building: state.building,
  currentRoom: state.currentRoom,
  currentItem: state.currentItem,
  reportDisagreements: state.reportDisagreements,
  roomDisagreementOverview: state.roomDisagreementOverview,
  generalReview: state.generalReview,
  askServices: state.askServices,
  statusValue: state.reportDisagreements
    ? state.generalReview === false
      ? state.pwa_data.damages[`${state.currentRoom}.${state.currentItem}`].evaluation
      : state.pwa_data.damages[state.currentRoom].evaluation
    : null,
  observationsValue: state.reportDisagreements
    ? state.generalReview === false
      ? state.pwa_data.damages[`${state.currentRoom}.${state.currentItem}`].description
      : state.pwa_data.damages[state.currentRoom].description
    : null,
  photos: state.reportDisagreements
    ? state.generalReview === false
      ? state.pwa_data.damages[`${state.currentRoom}.${state.currentItem}`].damagePhotos
      : state.pwa_data.damages[state.currentRoom].damagePhotos
    : null,
  currentColor: state.currentColor,
  colors: state.colors,
  buttonsColors: state.buttonsColors,
  localState: state.pwa_data,
  apiUrl: state.apiUrl,
});

const mapDispatchToProps = dispatch => ({
  nextRoom: () => dispatch(nextRoom()),
  previousRoom: () => dispatch(previousRoom()),
  nextItem: () => dispatch(nextItem()),
  previousItem: () => dispatch(previousItem()),
  reportDisagreement: () => dispatch(reportDisagreement()),
  cancelDisagreements: () => dispatch(cancelDisagreements()),
  openGeneralReview: () => dispatch(openGeneralReview()),
  openDisagreementsOverview: () => dispatch(openDisagreementsOverview()),
  closeDisagreementsOverview: () => dispatch(closeDisagreementsOverview()),
  openServices: () => dispatch(openServices()),
  closeGeneralReview: () => dispatch(closeGeneralReview()),
  closeServices: () => dispatch(closeServices()),
  setInitialState: obj => dispatch(setInitialState(obj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Base);
