import React from 'react';
import './StateMark.css';

function StateMark(props) {
  let mark = null

  if(props.text) {
    mark = 
      <div>
          <span 
              className={`dot ${props.text === 'Ótimo' || props.text === 'Bom' ? 'green' : props.text === 'Regular' ? 'orange' : props.text === 'Novo' ? 'new' : 'red'}`}
          >                                            
          </span>
          <span className="align-vertical">
              { props.text }
          </span>
      </div>;
  }
  return (
    mark
  );
}

export default StateMark;