import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import React from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import commonClasses from './Common/style.module.scss';
import './Overview.css';

export default class ItemsReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let damages = null;
    const currentDamages = [];

    for (let index = 0; index <= this.props.totalItems; index++) {
      let damage = JSON.parse(JSON.stringify(this.props.damages[this.props.currentRoom]));
      if (index !== 0) {
        damage = JSON.parse(JSON.stringify(this.props.damages[`${this.props.currentRoom}.${index}`]));
      }
      if (damage.description !== '') {
        if (index !== 0) {
          damage.item = this.props.roomItems[`${this.props.currentRoom}.${index}`].name;
        } else {
          damage.item = 'Geral';
        }
        currentDamages.push(damage);
      }
    }

    if (currentDamages.length > 0) {
      damages = currentDamages.map((damage, index) => (
        <Grid key={index} container row spacing={3} className={index === 0 ? 'first-box' : null}>
          <Grid item xs={12}>
            <Grid container row spacing={0} className={commonClasses.informationBox}>
              <Grid item xs={3}>
                <Avatar
                  src={damage.damagePhotos[0] ? damage.damagePhotos[0].localPath : null}
                  style={{ borderRadius: 0, width: 80, height: 80, margin: 1 }}
                />
              </Grid>
              <Grid item xs={9}>
                <div className="pa">
                  <span className="overview-item-title">{damage.item}</span>
                  <Typography color="textSecondary" gutterBottom className="overview-item-text">
                    {damage.description}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} className={commonClasses.separatorLine} />
            </Grid>
          </Grid>
        </Grid>
      ));
    }

    return <div className={commonClasses.scrollArea}>{damages}</div>;
  }
}
