import { connect } from 'react-redux'
import Overview from '../components/Overview'

const mapStateToProps = (state) => ({
    currentRoom: state.currentRoom,
    totalItems: Object.keys(state.building.building.rooms[state.currentRoom].items).length,
    roomItems: state.building.building.rooms[state.currentRoom].items,
    damages: state.pwa_data.damages,
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Overview)