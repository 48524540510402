import { connect } from 'react-redux';
import { nextRoom } from '../../actions';
import Start from './StartComponent';

const mapDispatchToProps = dispatch => ({
  clickStart: () => dispatch(nextRoom()),
});

export default connect(
  null,
  mapDispatchToProps
)(Start);
