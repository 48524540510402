import React, { Component } from 'react';
import PropTypes from 'prop-types';
import apiService from '../../services/api-service';
import GeralReview from '../../containers/GeralReview';
import ItemsReview from '../../containers/ItemsReview';
import Overview from '../../containers/Overview';
import RequestServices from '../../containers/RequestServices';
import Report from '../Report';
import RoomSteps from '../RoomSteps';
import Start from '../Start';
import BottomOptions from '../BottomOptions';
import HeaderNavigator from '../HeaderNavigator';
import Finish from '../Finish/FinishComponent';
import './Base.css';

class BaseComponent extends Component {
  static propTypes = {
    apiUrl: PropTypes.string.isRequired,
    localState: PropTypes.object.isRequired,
    askServices: PropTypes.bool.isRequired,
    building: PropTypes.object.isRequired,
    buttonsColors: PropTypes.array.isRequired,
    cancelDisagreements: PropTypes.func.isRequired,
    closeDisagreementsOverview: PropTypes.func.isRequired,
    closeGeneralReview: PropTypes.func.isRequired,
    closeServices: PropTypes.func.isRequired,
    colors: PropTypes.array.isRequired,
    currentColor: PropTypes.number.isRequired,
    currentItem: PropTypes.number.isRequired,
    currentRoom: PropTypes.number.isRequired,
    generalReview: PropTypes.bool.isRequired,
    nextItem: PropTypes.func.isRequired,
    nextRoom: PropTypes.func.isRequired,
    observationsValue: PropTypes.string,
    openDisagreementsOverview: PropTypes.func.isRequired,
    openGeneralReview: PropTypes.func.isRequired,
    openServices: PropTypes.func.isRequired,
    photos: PropTypes.array,
    previousItem: PropTypes.func.isRequired,
    reportDisagreement: PropTypes.func.isRequired,
    reportDisagreements: PropTypes.bool.isRequired,
    roomDisagreementOverview: PropTypes.bool.isRequired,
    statusValue: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      busy: false,
      finished: false,
      pendingImages: 0,
    };
  }

  prepareData = () => {
    const { localState } = this.props;
    const finalReport = {
      payload: {
        damages: {},
        selected_services: localState.services,
        services_observation: localState.servicesObservation,
      },
    };

    Object.keys(localState.damages).forEach(damage => {
      const currentDamage = localState.damages[damage];
      if (
        currentDamage.description !== '' &&
        currentDamage.evaluation !== null &&
        currentDamage.damagePhotos.length > 0
      ) {
        const damagePhotos = [];
        for (let index = 0; index < currentDamage.damagePhotos.length; index++) {
          const photo = currentDamage.damagePhotos[index];
          damagePhotos.push({
            id: photo.id,
            date: photo.date,
            filePath: photo.filePath,
          });
        }
        finalReport.payload.damages[damage] = {
          ...currentDamage,
          evaluation: JSON.parse(currentDamage.evaluation),
          damagePhotos,
        };
      }
    });

    return finalReport;
  };

  // Function to generate the final report and send it
  sendReport = () => {
    // eslint-disable-next-line no-restricted-globals
    this.setState({ busy: true, finished: true });
    this.makeRequest(this.prepareData());
  };

  makeRequest = async payload => {
    const { apiUrl } = this.props;

    // eslint-disable-next-line no-restricted-globals
    if (self.pendingRequests > 0) {
      // eslint-disable-next-line no-restricted-globals
      this.setState({ pendingImages: self.pendingRequests });
      setTimeout(() => this.makeRequest(payload), 1000);
    } else {
      // just make final request
      try {
        await apiService.post(apiUrl, payload, {
          headers: { 'Content-Type': 'application/json' },
        });
        this.setState({ busy: false });
      } catch (err) {
        console.log(err);
      }
    }
  };

  render() {
    const { busy, finished, pendingImages } = this.state;
    const {
      askServices,
      building,
      buttonsColors,
      cancelDisagreements,
      closeDisagreementsOverview,
      closeGeneralReview,
      closeServices,
      colors,
      currentColor,
      currentItem,
      currentRoom,
      generalReview,
      nextItem,
      nextRoom,
      observationsValue,
      openDisagreementsOverview,
      openGeneralReview,
      openServices,
      photos,
      previousItem,
      reportDisagreement,
      reportDisagreements,
      roomDisagreementOverview,
      statusValue,
    } = this.props;

    if (finished) {
      return <Finish busy={busy} pendingImages={pendingImages} />;
    }

    if (currentRoom === 0) {
      return <Start />;
    }

    if (currentItem === 0 && !generalReview && !askServices) {
      return <RoomSteps color={colors[currentColor]} />;
    }

    if (reportDisagreements) {
      let firstFunction;
      if (generalReview) {
        firstFunction = openDisagreementsOverview;
      } else if (currentItem < Object.keys(building.building.rooms[currentRoom].items).length) {
        firstFunction = nextItem;
      } else {
        firstFunction = openGeneralReview;
      }
      return (
        <>
          <HeaderNavigator title="Relatar" goBack={cancelDisagreements} />
          <Report buttonsColor={colors[currentColor]} />
          <BottomOptions
            text="Todos os detalhes em desacordo relatados?"
            step="ReportDisagreement"
            firstFunction={firstFunction}
            disabled={!(statusValue !== null && observationsValue !== '' && photos.length > 0)}
            color={colors[currentColor]}
            buttonsColor={buttonsColors[currentColor]}
          />
        </>
      );
    }

    if (roomDisagreementOverview) {
      return (
        <>
          <HeaderNavigator title="Resumo" goBack={closeDisagreementsOverview} />
          <Overview />
          <BottomOptions
            text="Todos os itens em desacordo listados?"
            step="roomDisagreementOverview"
            firstFunction={currentRoom < Object.keys(building.building.rooms).length ? nextRoom : openServices}
            color={colors[currentColor]}
            buttonsColor={buttonsColors[currentColor]}
          />
        </>
      );
    }

    if (generalReview) {
      return (
        <>
          <HeaderNavigator title="Geral" goBack={closeGeneralReview} />
          <GeralReview />
          <BottomOptions
            text="Todos os detalhes estão em acordo?"
            step="ItemsReview"
            firstFunction={reportDisagreement}
            secondFunction={openDisagreementsOverview}
            color={colors[currentColor]}
            buttonsColor={buttonsColors[currentColor]}
          />
        </>
      );
    }

    if (askServices) {
      return (
        <>
          <HeaderNavigator title="Requisar serviços" goBack={closeServices} />
          <RequestServices />
          <BottomOptions
            text="Todos os serviços requisitados?"
            step="AskServices"
            firstFunction={this.sendReport}
            color="#193860"
            buttonsColor="#2d5283"
          />
        </>
      );
    }

    return (
      <>
        <HeaderNavigator
          title={building.building.rooms[currentRoom].items[`${currentRoom}.${currentItem}`].name}
          goBack={previousItem}
        />
        <ItemsReview />
        <BottomOptions
          text="Todos os detalhes estão em acordo?"
          step="ItemsReview"
          firstFunction={reportDisagreement}
          secondFunction={
            currentItem < Object.keys(building.building.rooms[currentRoom].items).length ? nextItem : openGeneralReview
          }
          color={colors[currentColor]}
          buttonsColor={buttonsColors[currentColor]}
        />
      </>
    );
  }
}
export default BaseComponent;
