import { connect } from 'react-redux'
import { 
  setService,
  setServicesObservations
} from '../actions'
import RequestServices from '../components/RequestServices'

const mapStateToProps = (state) => ({
    availableServices: state.availableServices,
    servicesObservation: state.pwa_data.servicesObservation,
    selectedServices: state.pwa_data.services 
})

const mapDispatchToProps = (dispatch) => ({
  setService: (text) => dispatch(setService(text)),
  handleTextChange: (text) => dispatch(setServicesObservations(text)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestServices)