import Resizer from 'react-image-file-resizer';

const MAX_FILE_ALLOWED_SIZE = 1024;

const resizePhoto = async file => {
  try {
    const loadedImage = await new Promise(resolve => {
      const img = new Image();

      img.src = URL.createObjectURL(file);
      img.onload = () => resolve(img);
    });

    if (loadedImage.width < MAX_FILE_ALLOWED_SIZE || loadedImage.height < MAX_FILE_ALLOWED_SIZE) {
      return {
        resizedPhoto: file,
        url: loadedImage.src,
      };
    }

    const width = loadedImage.width > MAX_FILE_ALLOWED_SIZE ? MAX_FILE_ALLOWED_SIZE : loadedImage.width;
    const height = loadedImage.height > MAX_FILE_ALLOWED_SIZE ? MAX_FILE_ALLOWED_SIZE : loadedImage.height;

    const resizedPhoto = await new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        width,
        height,
        'jpeg',
        90,
        0,
        resizedFile => {
          resolve({
            resizedPhoto: resizedFile,
            url: loadedImage.src,
          });
        },
        'file'
      );
    });

    return resizedPhoto;
  } catch (error) {
    console.error('Error resizing photo:', error);
    return {
      resizedPhoto: file,
    };
  }
};

export default resizePhoto;
