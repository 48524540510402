import { connect } from 'react-redux';
import { nextItem, setReportObservations, setReportStatus, setReportPhoto } from '../../actions';
import Report from './ReportComponent';

const mapStateToProps = state => ({
  statusValue:
    state.generalReview === false
      ? state.pwa_data.damages[`${state.currentRoom}.${state.currentItem}`].evaluation
      : state.pwa_data.damages[state.currentRoom].evaluation,
  observationsValue:
    state.generalReview === false
      ? state.pwa_data.damages[`${state.currentRoom}.${state.currentItem}`].description
      : state.pwa_data.damages[state.currentRoom].description,
  photos:
    state.generalReview === false
      ? state.pwa_data.damages[`${state.currentRoom}.${state.currentItem}`].damagePhotos
      : state.pwa_data.damages[state.currentRoom].damagePhotos,
  uploadPath: state.uploadPath,
});

const mapDispatchToProps = dispatch => ({
  clickNext: () => dispatch(nextItem()),
  handleTextChange: text => dispatch(setReportObservations(text)),
  handleStatusChange: text => dispatch(setReportStatus(text)),
  handleNewPhoto: text => dispatch(setReportPhoto(text)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Report);
