import Grid from '@material-ui/core/Grid';
import ChatBubbleOutline from '@material-ui/icons/ChatBubbleOutline';
import OutlinedFlag from '@material-ui/icons/OutlinedFlag';
import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import StateMark from './StateMark';
import commonClasses from './Common/style.module.scss';
import './ItemsReview.css';

function VisibilityOutlined(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M12,6.6c3.8,0,7.2,2.1,8.8,5.5c-1.7,3.4-5,5.5-8.8,5.5c-3.8,0-7.2-2.1-8.8-5.5C4.8,8.7,8.2,6.6,12,6.6
			 M12,4.6c-5,0-9.3,3.1-11,7.5c1.7,4.4,6,7.5,11,7.5s9.3-3.1,11-7.5C21.3,7.7,17,4.6,12,4.6L12,4.6z"
      />
      <path
        d="M12,9.6c1.4,0,2.5,1.1,2.5,2.5c0,1.4-1.1,2.5-2.5,2.5c-1.4,0-2.5-1.1-2.5-2.5C9.5,10.7,10.6,9.6,12,9.6
			 M12,7.6c-2.5,0-4.5,2-4.5,4.5c0,2.5,2,4.5,4.5,4.5c2.5,0,4.5-2,4.5-4.5C16.5,9.6,14.5,7.6,12,7.6L12,7.6z"
      />
    </SvgIcon>
  );
}

export default class ItemsReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getImages = this.getImages.bind(this);
  }

  getImages() {
    const items = this.props.item.photos;
    const images = [];

    items.forEach(photo => {
      images.push({
        original: photo.filePath,
      });
    });

    if (images.length === 0) {
      images.push({
        original: '/images/blank.png',
      });
    }

    return images;
  }

  render() {
    let sectionObservations = null;
    let sectionStatus = null;
    let details = null;
    let sectionDetails = null;

    if (this.props.item.observation) {
      sectionObservations = (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={0} className={commonClasses.informationBox}>
              <Grid item xs={1}>
                <ChatBubbleOutline />
              </Grid>
              <Grid item xs={11}>
                <div>
                  <span className="review-category-title">OBSERVAÇŌES</span>
                </div>
                <div>
                  <span className="review-category-text">{this.props.item.observation}</span>
                </div>
              </Grid>
              <Grid item xs={12} className={commonClasses.separatorLine} />
            </Grid>
          </Grid>
        </Grid>
      );
    }

    if (this.props.item.details) {
      details = this.props.item.details.map(detail => (
        <div key={detail.label}>
          <span className="detail-name">{detail.label}:&nbsp;</span>
          <span className="review-category-text">{detail.value}</span>
        </div>
      ));
    }

    if (this.props.item.details) {
      if (this.props.item.details.length > 0) {
        sectionDetails = (
          <Grid container spacing={3} className="review-details-grid">
            <Grid item xs={12}>
              <Grid container spacing={0} className={commonClasses.informationBox}>
                <Grid item xs={1}>
                  <VisibilityOutlined />
                </Grid>
                <Grid item xs={11}>
                  <div>
                    <span className="review-category-title">DETALHES</span>
                  </div>
                  <div>{details}</div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }
    }

    if (this.props.item.evaluation) {
      sectionStatus = (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={0} className={commonClasses.informationBox}>
              <Grid item xs={1}>
                <OutlinedFlag />
              </Grid>
              <Grid item xs={11}>
                <div>
                  <span className="review-category-title">ESTADO</span>
                </div>
                <StateMark text={this.props.item.evaluation ? this.props.item.evaluation.description : null} />
              </Grid>
              <Grid item xs={12} className={commonClasses.separatorLine} />
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <>
        <div className={commonClasses.scrollArea}>
          <div className="review-images-gallery">
            <Carousel showThumbs={false} showStatus={false} showArrows={false}>
              {this.getImages().map((value, index) => (
                <div key={index} className="camera-box">
                  <img height="100%" src={value.original} alt="" />
                </div>
              ))}
            </Carousel>
          </div>
          {sectionObservations}
          {sectionStatus}
          {sectionDetails}
        </div>
      </>
    );
  }
}
