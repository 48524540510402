import axios from 'axios';
import config from '../config';

const instance = axios.create({
  baseURL: config.API_BASE_URL,
});

// Add a request interceptor
instance.interceptors.request.use(
  // eslint-disable-next-line no-shadow
  config => {
    // eslint-disable-next-line no-restricted-globals
    self.pendingRequests++;
    return config;
  },
  err => Promise.reject(err)
);

// Add a response interceptor
instance.interceptors.response.use(
  res => {
    self.pendingRequests--; // eslint-disable-line no-restricted-globals
    return res;
  },
  err => Promise.reject(err)
);

export default instance;
