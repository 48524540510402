import React from 'react';
import './RequestServices.css';
import { withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import BuildIcon from '@material-ui/icons/Build';
import ChatIcon from '@material-ui/icons/ChatBubbleOutline';
import Grid from '@material-ui/core/Grid';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'inherit',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'inherit',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey[100]',
      },
      '&:hover fieldset': {
        borderColor: 'inherit',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'inherit',
      },
    },
  },
})(TextField);

const DarkCheckbox = withStyles({
  root: {
    color: grey[800],
    '&$checked': {
      color: grey[900],
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

  export default class RequestServices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentWillMount() {
      if(this.props.selectedServices.length > 0) {
        this.props.selectedServices.forEach(service => {
          this.setState({
            ...this.state,
            [service.id]: true
          });
        });
      }
    }

    handleChange = service => event => {
      this.setState({
        ...this.state,
        [service.id]: event.target.checked
      });

      this.props.setService(service);
    };

    render() {
      let services = null;

      if (this.props.availableServices.length > 0) {
        services =
            this.props.availableServices.map((service) =>
            <FormControlLabel key={service.id} className="checkboxLabelColor"
            control={
              <DarkCheckbox checked={this.state[service.id]} onChange={this.handleChange(service)} value={service.id} />
            }
            label={service.label}
          />
        );
      }

      return (
        <div className="scroll-area">
          <Grid container row spacing={3}>
              <Grid item xs={12}>
                  <Grid container row spacing={0} className="request-first-item information-box">
                      <Grid item xs={1}>
                        <BuildIcon className="icon" />
                      </Grid>
                      <Grid item xs={11} className="review-text">
                          <div>
                              <span className="review-category-title">
                                REQUISITAR
                              </span>
                          </div>
                          <div className="services-description-text">
                            <span className="services-text">
                              Selecione abaixo os serviços necessários para efetiva a desocupaçao do imóvel.
                            </span>
                          </div>
                          <div className="services-text">
                              { services }
                          </div>
                      </Grid>
                      <Grid item xs={12} className="services-separator-line">
                      </Grid>
                  </Grid>
              </Grid>
          </Grid>
          <Grid container row spacing={3}>
              <Grid item xs={12}>
                  <Grid container row spacing={0} className="information-box">
                      <Grid item xs={1}>
                        <ChatIcon className="icon services-title" />
                      </Grid>
                      <Grid item xs={11} className="review-text">
                          <div>
                              <span className="review-category-title">
                                OBSERVAÇŌES
                              </span>
                          </div>
                          <div className="report-observations">
                            <FormGroup className="">
                              <CssTextField
                                placeholder=""
                                variant="outlined"
                                id="custom-css-outlined-input"
                                multiline
                                value={this.props.servicesObservation}
                                onChange={(e) => this.props.handleTextChange(e.target.value)}
                              />
                            </FormGroup>
                          </div>
                      </Grid>
                  </Grid>
              </Grid>
          </Grid>
        </div>
      );
    }
  }