import React from 'react';
import PropTypes from 'prop-types';
import './HeaderNavigator.css';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    marginRight: '48px',
    fontSize: '22px',
  },
}));

function HeaderNavigator(props) {
  const classes = useStyles();

  return (
    <AppBar position="fixed" color="white" elevation={0} className="App-bar">
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="#707070"
          aria-label="Menu"
          onClick={props.goBack}
        >
          <ArrowBackIos />
        </IconButton>
        <Typography variant="h5" className={classes.title}>
          {props.title}
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

HeaderNavigator.propTypes = {
  title: PropTypes.string.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default HeaderNavigator;
