import React from 'react';
import { CircularProgress } from '@material-ui/core';
import classes from './style.module.scss';

const Loading = ({ children, current, total }) => (
  <div className={classes.loading}>
    {total > 0 ? (
      <div className={classes.text}>
        {current}/{total}
      </div>
    ) : null}
    <CircularProgress size={64} />
    {children ? <div className={classes.children}>{children}</div> : null}
  </div>
);

export default Loading;
